@import '_variables.scss';

.modalShade {
    background-color: #000000A0;
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .materialModal{
        width: auto;
        height: auto;
        background: #222222;
        border: thin solid #DDDDDD60;
        border-radius: 15px;
        overflow: hidden;
        max-width: 90vw;
        max-height:90vh;
        filter: drop-shadow(0px 0px 2px #00000090);
        cursor: auto;

        .modalContent{
            display: flex;
            justify-content: flex-end;
            position: relative;

            .imageContainer{
                width: auto;
                position: relative;
                min-width: 750px;

                .materialImage{
                    width:100%;
                    height:100%;
                    transition: all 400ms ease-in-out;
                }
                .imageSelector{
                    width: 100%;
                    height: 65px;
                    bottom: 10px;
                    position: absolute;
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: flex-end;

                    .swatch{
                        width:50px;
                        height:50px;
                        border-radius:50%;
                        border:3px solid #DDD;
                        transition: all 400ms ease-in-out;
                        -webkit-transition: all 400ms ease-in-out;
                        
                        overflow: hidden;
                        isolation: isolate;
                        transform: translate(0);
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        img{
                            width:130%;
                            filter: drop-shadow(0px 0px 2px #444);
                        }

                        &.active{
                            border-color: #FEFEFE;
                            margin-bottom:10px;
                        }
                       

                    }
                }
            }
            .dataContainer{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: #272b2bf8;
                box-sizing: content-box;
                width: 430px;

               

                .closeButton{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    display: flex;
                    cursor: pointer;
                    color: #818181;
                    z-index: 1;

                    &:after{
                        content: "";
                        background: #2d3232;
                        width: 100px;
                        height: 50px;
                        right: -22px;
                        top: -22px;
                        border-radius: 20px;
                        position: absolute;
                        z-index: -1;
                    }

                    svg{
                        stroke:#818181;
                    }

                    &:hover{
                        color:#FEFEFE;

                        svg{
                            stroke:#FEFEFE;
                        }
                       
                    }

                  
                }

                h1{
                    color: #00ffed;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin: 0;
                    text-align: center;
                    font-family: "Montserrat-Bold";
                }
                h3{
                    color:#DDDDDD;
                    margin:0;
                    text-align: center;
                }
                h6{
                    color:#DDDDDD;
                    margin:0;
                    text-align: center;
                    text-transform: uppercase;
                }

                .dataContainerTop{
                    background-color: #272b2bf8;
                    padding: 25px 10px 10px 10px;
                    box-sizing: content-box;
                    margin-left: 2px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                  

                .infoBox{
                    border:3px solid #DDDDDD;
                    border-radius:6px;
                    display: flex;
                    width: 100%;
                    height: inherit;
                    margin-top:10px;

                    .infoContent{
                        width:100%;
                        display:flex;
                        flex-direction: column;
                        color: #DDDDDD;

                        &:nth-child(2){
                            border-left: 3px solid #DDDDDD;
                        }

                        h4{
                            margin:0;
                            text-transform: uppercase;
                           

                            &.pageLink{
                                cursor: pointer;
                            }
                        }


                        .infoContentHeader{
                            height:25px;
                            background-color: #DDDDDD;
                            color: $dark_background;
                            padding: 5px 0px 0px 5px;
                        }
                        .infoContentBody{
                            padding: 8px 0 8px 8px;
                            max-height: 100px;
                            overflow: scroll;

                            &::-webkit-scrollbar-thumb {
                                width: 5px;
                                height: 8px;
                              }

                            

                            .price{
                                display:flex;
                                justify-content: center;
                                align-items: flex-end;
                                color:#DDDDDD;
                                position: relative;


                                .value{
                                    font-size:30px;
                                    font-weight:bold;
                                    font-family: "Montserrat-Bold";
                                }
                                .uom{
                                    font-size: 12px;
                                    margin-bottom: 6px;
                                    margin-left: 3px;
                                    font-weight:bold;
                                }
                                .disclaimer{
                                    font-size: 8px;
                                    position: absolute;
                                    opacity: 0.6;
                                    bottom: -10px;
                                    text-transform: uppercase;
                                }
                            }
                        }
                        .editButton{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 14px;
                            cursor: pointer;

                            svg{
                                transform: translate(7px, -1px);
                            }

                           
                        }
                        .editButton:hover{
                            color:#00ffed;
                            
                        }
                        
                        
                    }
                    
                    }
                }
            }
        }

    }
    .dataContainerBottom{
        height: 60px;
        width: 100%;
        background-color: #00000010;
        padding: 10px;
        display: flex;
        gap:2px;
        border-top: thin solid #DDDDDD20;
        box-sizing: border-box;

        .bottomButton{
            border: thin solid #00ffed;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #00ffed;
            border-radius: 3px;
            text-transform: uppercase;
            width: inherit;
            cursor: pointer;
            user-select: none;
            transition:all 300ms ease-in-out;

            &:hover{
                border: thin solid #DDDDDD;
                color: #DDD;
                background-color: #DDDDDD20;

            }

        }
    }
}

@media only screen and (min-width: 1280px) {
    .modalShade {

    
        .materialModal{

    
            .modalContent{
                h1{
                    font-size:20px;
                }
                h3{
                    font-size: 16px;
                }
                h4{
                    font-size: 13px;
                }
    
                .imageContainer{
                    width: auto;
                    position: relative;
    
                }
                .dataContainer{

                    .dataContainerTop{
                        min-width: 400px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .modalShade {

    
        .materialModal{

    
            .modalContent{
                h1{
                    font-size:16px;
                }
                h3{
                    font-size: 13px;
                }
                h4{
                    font-size: 11px;
                }

    
                .imageContainer{
                    width: auto;
                    position: relative;
    
                }
                .dataContainer{
                    width: 430px;
                }
            }
        }
    }
}


@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {

    .modalShade {
        background-color: #000000A0;
        position: fixed;
        top:0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    
        .materialModal{
            width: 90vw;
            height: 90vh;
            background: #222222;
            border: thin solid #DDDDDD60;
            border-radius: 15px;
            overflow: hidden;
            max-width: 90vw;
            max-height:90vh;
            filter: drop-shadow(0px 0px 2px #00000090);
            cursor: auto;
    
            .modalContent{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: relative;
    
                .imageContainer{
                    width: auto;
                    height: 450px !important;
    
                    .materialImage{
                        width:100%;
                        height:450px;
                        transition: all 400ms ease-in-out;
                    }
                    .imageSelector{
                        width: 100%;
                        height: 65px;
                        bottom: 10px;
                        position: absolute;
                        display: flex;
                        gap: 5px;
                        justify-content: center;
                        align-items: flex-end;
    
                        .swatch{
                            width:50px;
                            height:50px;
                            border-radius:50%;
                            border:3px solid #DDD;
                            transition: all 400ms ease-in-out;
                            -webkit-transition: all 400ms ease-in-out;
                            
                            overflow: hidden;
                            isolation: isolate;
                            transform: translate(0);
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
    
                            img{
                                width:130%;
                                filter: drop-shadow(0px 0px 2px #444);
                            }
    
                            &.active{
                                border-color: #FEFEFE;
                                margin-bottom:10px;
                            }
                           
    
                        }
                    }
                }
                .dataContainer{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: #272b2bf8;
                    box-sizing: content-box;
                    width: 100%;
                    height: 460px !important;
    
                   
    
                    .closeButton{
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        display: flex;
                        cursor: pointer;
                        color: #818181;
                        z-index: 1;
    
                        &:after{
                            content: "";
                            background: #2d3232;
                            width: 100px;
                            height: 50px;
                            right: -22px;
                            top: -22px;
                            border-radius: 20px;
                            position: absolute;
                            z-index: -1;
                        }
    
                        svg{
                            stroke:#818181;
                        }
    
                        &:hover{
                            color:#FEFEFE;
    
                            svg{
                                stroke:#FEFEFE;
                            }
                           
                        }
    
                      
                    }
    
                    h1{
                        color: #00ffed;
                        font-weight: bold;
                        text-transform: uppercase;
                        margin: 0;
                        text-align: center;
                        font-family: "Montserrat-Bold";
                    }
                    h3{
                        color:#DDDDDD;
                        margin:0;
                        text-align: center;
                    }
                    h6{
                        color:#DDDDDD;
                        margin:0;
                        text-align: center;
                        text-transform: uppercase;
                    }
    
                    .dataContainerTop{
                        background-color: #272b2bf8;
                        padding: 10px;
                        box-sizing: content-box;
                        margin-left: 2px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        overflow: scroll;
                      
    
                    .infoBox{
                        border:3px solid #DDDDDD;
                        border-radius:6px;
                        display: flex;
                        width: 100%;
                        height: inherit;
                        margin-top:10px;
    
                        .infoContent{
                            width:100%;
                            display:flex;
                            flex-direction: column;
                            color: #DDDDDD;
    
                            &:nth-child(2){
                                border-left: 3px solid #DDDDDD;
                            }
    
                            h4{
                                margin:0;
                                text-transform: uppercase;
                               
    
                                &.pageLink{
                                    cursor: pointer;
                                }
                            }
    
    
                            .infoContentHeader{
                                height:25px;
                                background-color: #DDDDDD;
                                color: $dark_background;
                                padding: 5px 0px 0px 5px;
                            }
                            .infoContentBody{
                                padding: 8px 0 8px 8px;
                                max-height: auto;
                                overflow: hidden;
    
                                &::-webkit-scrollbar-thumb {
                                    width: 5px;
                                    height: 8px;
                                    background-color: orange; /* or add it to the track */
                                  }
    
    
    
                                .price{
                                    display:flex;
                                    justify-content: center;
                                    align-items: flex-end;
                                    color:#DDDDDD;
                                    position: relative;
    
    
                                    .value{
                                        font-size:30px;
                                        font-weight:bold;
                                        font-family: "Montserrat-Bold";
                                    }
                                    .uom{
                                        font-size: 12px;
                                        margin-bottom: 6px;
                                        margin-left: 3px;
                                        font-weight:bold;
                                    }
                                    .disclaimer{
                                        font-size: 8px;
                                        position: absolute;
                                        opacity: 0.6;
                                        bottom: -10px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            
                            
                        }
                        
                        }
                    }
                }
            }
    
        }
        .dataContainerBottom{
            height: 60px;
            width: 100%;
            background-color: #00000010;
            padding: 10px;
            display: flex;
            gap:2px;
            border-top: thin solid #DDDDDD20;
            box-sizing: border-box;
    
            .bottomButton{
                border: thin solid #00ffed;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #00ffed;
                border-radius: 3px;
                text-transform: uppercase;
                width: inherit;
                cursor: pointer;
                user-select: none;
                transition:all 300ms ease-in-out;
    
                &:hover{
                    border: thin solid #DDDDDD;
                    color: #DDD;
                    background-color: #DDDDDD20;
    
                }
    
            }
        }
    }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .modalShade {

    
        .materialModal{

    
            .modalContent{
                h1{
                    font-size:16px;
                }
                h3{
                    font-size: 13px;
                }
                h4{
                    font-size: 10px;
                }

    
                .imageContainer{
                    width: auto;
                    position: relative;
                    min-width: 600px;
                    height:500px !important;
    
                }
                .dataContainer{
                    width: 430px;
                    height:500px !important;

                    .infoContent{
                        .infoContentHeader{
                            padding: 4px 0 2px 5px !important;
                            height:20px !important;
                        }
                        .infoContentBody{
                            overflow: hidden !important;
                                .price{
    
    
                                    .value{
                                        font-size:20px !important;

                                    }
                                    .uom{
                                        margin-bottom: 2px !important;
                                    }
                                    .disclaimer{
                                        font-size: 6px !important;
                                        position: absolute;
                                        opacity: 0.6;
                                        bottom: -10px;
                                        text-transform: uppercase;
                                    } 
                            }
                        }
                    }
                }
            }
        }
    }

}