@import './variables';

.swatch{
    background-color: $dark_background_solid;
    overflow: hidden;
    border-radius: 3px;
    border: thin solid black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        cursor: pointer;
    }
    .imageBlur{
        filter: blur(6px);
        transition: all 300ms ease-in-out;

        &.loaded{
            filter:blur(0px)
        }
    }
    .logo{
       

        svg{
        width: 45px;
        height: 35px;
        fill:#FEFEFE;
        filter: drop-shadow(0px 3px 2px #00000080);
        }
    }
    .toolTip{
        background: #FEFEFE90;
        width: 330px;
        color: black;
        padding: 10px;
        border-radius: 2px;
        position: absolute;
        transition: all ease-in-out 200ms;
    }
    
    .vendor{
        position: absolute;
        top:5px;
        left: 5px;
        text-transform: uppercase;
        filter:drop-shadow(0px 0px 2px #444);
        font-weight:bold;
        color:#FEFEFE;
        font-size:12px;
        height:20px;
        

        :hover{
            transition: all ease-in-out 200ms;
            color: #000;
            filter:drop-shadow(0px 0px 2px #FEFEFE);
        }

        .vendorLabel{
            cursor: pointer;
        }

    }
    .collectionButton{
        position: absolute;
        top:5px;
        right: 5px;
        filter:drop-shadow(0px 0px 2px #444444);
        height:20px;

        svg{
            fill:#FEFEFE;
        }
    }
    .imageSelector{
        width: 100%;
        height: 20px;
        bottom: 60px;
        position: absolute;
        display: flex;
        justify-content: center;
        gap:6px;

        span{

            width: 20px;
            height: 20px;
            border: 3px solid #FEFEFE;
            border-radius: 10px;
        }

    }
    @keyframes gradient {
        0% {
            background-position: 120% ;
        }
        50% {
            background-position: 50%;
        }
        100% {
            background-position: -20%;
        }
    }
    @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      
    .spinner{
        animation: spin 0.6s linear infinite;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        border: 3px solid white;
        border-bottom: 3px solid gray;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        filter: drop-shadow(0px 0px 2px black);
    }

    .information{
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;
        overflow: hidden;
        position: relative;
        width: 100%;
        background-color: #00000010;

        .loadingBar{
            background: linear-gradient(90deg, transparent, #444444 50%,transparent, transparent,);
            background-size: 400%;
            animation: gradient 0.6s linear infinite;
            width: 100%;
            height: 2px;
            top: 0px;
            position: absolute;
          }

        h3{
            font-size:16px;
            margin:0;
            text-transform: uppercase;
            color: $dark_text_bright;
            text-align: center;
        }
        h4{
            font-size:12px;
            margin:0;
            text-align: center;
            color:$dark_text_dim;
        }
    }
}

@media only screen and (min-width: 1281px) {
    .swatch{
        width: 332px;
        height: fit-content;
        min-height:300px;
        max-width: 345px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 345px;

        img{
            width:100%;
            height:100%;
    
    
        }
    }
}
@media only screen and (max-width: 1280px) {
    .swatch{
        width: 305px;
        height: 275px;
        max-width: 375px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 305px;

        img{
            width:100%;
            height:auto;
    
    
        }
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    .swatch{
        height: 275px;
        max-width: 328px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 360px;

        img{
            width:100%;
            height:auto;
    
    
        }
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .swatch{
        height: 275px;
        max-width: 366px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 360px;

        img{
            width:100%;
            height:auto;
    
    
        }
    }
}
@media only screen and (max-width: 760px){
    .swatch{
        .imageSelector{
            width: 100%;
            height: 40px;
            bottom: 40px;
            position: absolute;
            display: flex;
            justify-content: center;
            grid-gap: 6px;
            gap: 6px;
            align-items: center;
            background-color:#a3a3a333
        }

    }

}