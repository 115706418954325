// $dark_background: #272b2bf8;
// $light_background: #FEFEFEF8;
// $dark_text: #FEFEFE;
// $dark_text_highlight: #00ffed;

@import '_variables.scss';

.bar{
    display: flex;
    height: 5rem;
    border-bottom: 3px solid black;
    background-color: $dark_background;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;

    .logo{
        width: 10rem;
        color: $dark_text;
        display: flex;
        justify-content: center;
        font-size: 30px;
        cursor: pointer;
        user-select: none;

    }
    .search{
        border-bottom: 2px solid #FEFEFE;
        width: 30%;
        display: flex;
        justify-content: center;
        position: relative;

        input[type=text]{
            width: 100%;
            height: 40px;
            background: transparent;
            border: none;
            font-size: 24px;
            outline: none;
            text-align: center;
            color: #FEFEFE;
            font-family: "Montserrat";
        }
        .clearSearch{
            position: absolute;
            top: 10px;
            right: -5px;
            cursor: pointer;
            svg{
                height: 25px;
                width: 25px;
                stroke: $dark_text;
                transition: stroke 500ms ease-in-out;
                &:hover{
                    stroke: $dark_text_highlight
                }
            }
        }
    }
    .filter{
            color:$dark_text;
            padding: 0 10px;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:0 10px; 
            gap:12px;
            cursor: pointer;

            &:hover{
                color:$dark_text_highlight;
            }
            &.active{
                color:$dark_text_highlight;
            }
            
    }


    .navigation{
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 3rem;
        color:$dark_text;
        user-select: none;
        min-width:33%;

        a{
            text-decoration: none;
            color:$dark_text;
            padding: 0 10px;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:0 10px;    
            user-select: none;
            width:150px;

        }
        .collectionCount{
            background-color: #FEFEFE;
            min-width: 20px;
            margin-left: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            color: black;
            font-size: 15px;
            height: 16px;
            padding: 0 2px;
        }
        .userMenu{
            cursor:pointer;
            margin-left: 1em;
            transition: color 200ms ease-in-out;
            color: #FEFEFE;

            &:hover{
                color: $dark_text_highlight;
            }
            &.active{
                color: $dark_text_highlight;
            }
        }
    }

}

.filterBox{
    width: 400px;
    height: auto;
    background-color: #272b2bC9;
    position: fixed;
    top: 85px;
    right: 210px;
    z-index: 2;
    border-radius: 10px;
    padding: 25px 20px;
    color: $dark_text_bright;
    overflow: hidden;
    filter: drop-shadow(0px 0px 3px black);
    border: thin solid #DDDDDD50;
    opacity:1;
    transition: all 300ms ease-in-out;
    visibility: visible;

    &.hidden{
       opacity: 0;
       top:80px;
        visibility: hidden;
    }
    .closeButton{
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        cursor: pointer;
        color: #818181;

        svg{
            stroke:#818181;
        }

        &:hover{
            color:#FEFEFE;

            svg{
                stroke:#FEFEFE;
            }
           
        }

      
    }
    .content{
        display: flex;
        flex-direction: column;
        gap:2px;
        .header{
            color:$dark_text_highlight;
            font-size:10px;
            height: 20px;
        }
        .arrayFilter{
            height: 30px;
            display: flex;
            justify-content: flex-start;
            gap: 2px;
            margin-bottom: 10px;

            .button{
                border: thin solid #DDDDDD80;
                border-radius: 3px;
                color: #DDDDDD80;
                height: 30px;
                width: -webkit-fill-available;
                font-size: 10px;
                cursor: pointer;
                padding: 0 15px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                justify-content: center;

                &:hover{
                    border-color: #DDD;
                    color:#DDD;
                }
                &.active{
                    border-color: #00ffed;
                    color:#00ffed;
                    font-family:"Montserrat-bold";
                }
            }
        }
        .priceRangeModule{
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .priceValue{
                color: #00ffed;
                font-size: 12px;

               
            }
            .rangeSlider{
                visibility: hidden;

            }
           
            .slider{
                background-color: #DDDDDD50;
                height: 5px;
                border-radius: 3px;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .sliderHighlight{
                    background-color: #00ffed;
                    height: 8px;
                    border-radius: 3px;
                    width: 69%;
                    position: absolute;
                    z-index: 1;
                    left: 29px;
                }

                .sliderHandle{
                    width: 12px;
                    height: 20px;
                    background-color: #00ffed;
                    position: absolute;
                    border-radius: 2px;
                    top: -8px;
                    transform: skew(-10deg, 0deg);
                    filter: drop-shadow(0 0 3px black);
                    z-index: 2;

                }
            }
        }
        .priceFilter{
            display: flex;
            flex-direction: column;


            .range{
                font-size: 12px;
                text-transform: uppercase;
                display: flex;
                gap: 4px;
                justify-content: center;
                align-items: center;
            }
        }
        .reset{
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color:#00ffed;
            text-transform: uppercase;
            margin-top: 20px;
            cursor: pointer;
            border:#00ffed thin solid;
            border-radius: 3px;
            transition: all 200ms ease-in-out;

            &:hover{
                color:#fefefe;
                border-color: #fefefe;
            }
        }
    }

}
.userBox{
    width:200px;
    height: auto;
    background-color: $dark_background;
    position: fixed;
    top: 85px;
    right: 40px;
    z-index: 2;
    border-radius: 10px;
    padding: 10px;
    color: $dark_text_bright;
    overflow: hidden;
    filter: drop-shadow(0px 0px 3px black);
    border: thin solid #FEFEFE30;
    opacity:1;
    transition: all 300ms ease-in-out;
    visibility: visible;

    &.hidden{
       opacity: 0;
       top:80px;
        visibility: hidden;
    }

    .userBoxContent{
        display: flex;
        flex-direction: column;
        gap:2px;

        .menuButton{
            font-size: 12px;
            background-color: #DDD;
            color: #222;
            text-align: center;
            padding: 10px;
            border-radius: 3px;
            text-transform: uppercase;
            transition: all 300ms ease-in-out;

            &:hover{
                background-color:#222;
                color:#DDD;
            }
        }

        .userRole{
            display: flex;
            font-size: 10px;
            gap: 2px;
            justify-content: center;
            align-items: center;
            margin: 5px 0px 10px 0px;
            user-select: none;
            color: #00ffed;

            svg{
                width:20px;
                height:20px;
            }
        }

        .userBoxName{
            background-color: #DDD;
            color:#222;
            text-align: center;
            padding: 10px;
            border-radius: 3px;
            margin-top:20px;
            text-transform: uppercase;

            h4{
                font-family: "Montserrat-Bold";
                font-size: 12px;
                user-select: none;

            }
        }
    }
}


@media only screen and (max-width: 760px){
    .bar{
        height: 3rem;
        padding: 10px;
    
        .logo{
            width: 2rem;
            font-size: 16px;
            justify-content: flex-start;
    
        }
        .search{
            border: 1px solid #FEFEFE;
            width: 55%;
            display: flex;
            justify-content: center;
            position: relative;
            border-radius: 20px;
            padding: 0 3px;
            box-sizing: content-box;
    
            input[type=text]{
                width: 100%;
                height: 24px;
                background: transparent;
                border: none;
                font-size: 12px;
                outline: none;
                text-align: center;
                color: #FEFEFE;
                font-family: "Montserrat";
            }
            .clearSearch{
                position: absolute;
                top: 2px;
                right: 5px;
                cursor: pointer;
                svg{
                    height: 20px;
                    width: 20px;
                    stroke: $dark_text;
                    transition: stroke 500ms ease-in-out;
                    &:hover{
                        stroke: $dark_text_highlight
                    }
                }
            }
        }
    
    
        .navigation{
            width: 24px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 0rem;
            color:$dark_text;
            user-select: none;
            min-width:0px;
    
            a{
                text-decoration: none;
                color:$dark_text;
                padding: 0 10px;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin:0 10px;    
                user-select: none;
                width:150px;
    
            }
            .userMenu{
                cursor:pointer;
                margin-top:5px;
                margin-left: 1em;
                transition: color 200ms ease-in-out;
                color: #FEFEFE;
    
                &:hover{
                    color: $dark_text_highlight;
                }
                &.active{
                    color: $dark_text_highlight;
                }
            }
        }
    
    }
    
    .filterBox{
        width: 400px;
        height: auto;
        background-color: #272b2bC9;
        position: fixed;
        top: 85px;
        right: 210px;
        z-index: 2;
        border-radius: 10px;
        padding: 25px 20px;
        color: $dark_text_bright;
        overflow: hidden;
        filter: drop-shadow(0px 0px 3px black);
        border: thin solid #DDDDDD50;
        opacity:1;
        transition: all 300ms ease-in-out;
        visibility: visible;
    
        &.hidden{
           opacity: 0;
           top:80px;
            visibility: hidden;
        }
        .closeButton{
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            cursor: pointer;
            color: #818181;
    
            svg{
                stroke:#818181;
            }
    
            &:hover{
                color:#FEFEFE;
    
                svg{
                    stroke:#FEFEFE;
                }
               
            }
    
          
        }
        .content{
            display: flex;
            flex-direction: column;
            gap:2px;
            .header{
                color:$dark_text_highlight;
                font-size:10px;
                height: 20px;
            }
            .arrayFilter{
                height: 30px;
                display: flex;
                justify-content: flex-start;
                gap: 2px;
                margin-bottom: 10px;
    
                .button{
                    border: thin solid #DDDDDD80;
                    border-radius: 3px;
                    color: #DDDDDD80;
                    height: 30px;
                    width: -webkit-fill-available;
                    font-size: 10px;
                    cursor: pointer;
                    padding: 0 15px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    justify-content: center;
    
                    &:hover{
                        border-color: #DDD;
                        color:#DDD;
                    }
                    &.active{
                        border-color: #00ffed;
                        color:#00ffed;
                        font-family:"Montserrat-bold";
                    }
                }
            }
            .priceRangeModule{
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
    
                .priceValue{
                    color: #00ffed;
                    font-size: 12px;
    
                   
                }
                .rangeSlider{
                    visibility: hidden;
    
                }
               
                .slider{
                    background-color: #DDDDDD50;
                    height: 5px;
                    border-radius: 3px;
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .sliderHighlight{
                        background-color: #00ffed;
                        height: 8px;
                        border-radius: 3px;
                        width: 69%;
                        position: absolute;
                        z-index: 1;
                        left: 29px;
                    }
    
                    .sliderHandle{
                        width: 12px;
                        height: 20px;
                        background-color: #00ffed;
                        position: absolute;
                        border-radius: 2px;
                        top: -8px;
                        transform: skew(-10deg, 0deg);
                        filter: drop-shadow(0 0 3px black);
                        z-index: 2;
    
                    }
                }
            }
            .priceFilter{
                display: flex;
                flex-direction: column;
    
    
                .range{
                    font-size: 12px;
                    text-transform: uppercase;
                    display: flex;
                    gap: 4px;
                    justify-content: center;
                    align-items: center;
                }
            }
            .reset{
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                color:#00ffed;
                text-transform: uppercase;
                margin-top: 20px;
                cursor: pointer;
                border:#00ffed thin solid;
                border-radius: 3px;
                transition: all 200ms ease-in-out;
    
                &:hover{
                    color:#fefefe;
                    border-color: #fefefe;
                }
            }
        }
    
    }
    .userBox{
        width:200px;
        height: auto;
        background-color: $dark_background;
        position: fixed;
        top: 85px;
        right: 40px;
        z-index: 2;
        border-radius: 10px;
        padding: 10px;
        color: $dark_text_bright;
        overflow: hidden;
        filter: drop-shadow(0px 0px 3px black);
        border: thin solid #FEFEFE30;
        opacity:1;
        transition: all 300ms ease-in-out;
        visibility: visible;
    
        &.hidden{
           opacity: 0;
           top:80px;
            visibility: hidden;
        }
    
        .userBoxContent{
            display: flex;
            flex-direction: column;
            gap:2px;
    
            .menuButton{
                font-size: 12px;
                background-color: #DDD;
                color: #222;
                text-align: center;
                padding: 10px;
                border-radius: 3px;
                text-transform: uppercase;
                transition: all 300ms ease-in-out;
    
                &:hover{
                    background-color:#222;
                    color:#DDD;
                }
            }
    
            .userRole{
                display: flex;
                font-size: 10px;
                gap: 2px;
                justify-content: center;
                align-items: center;
                margin: 5px 0px 10px 0px;
                user-select: none;
                color: #00ffed;
    
                svg{
                    width:20px;
                    height:20px;
                }
            }
    
            .userBoxName{
                background-color: #DDD;
                color:#222;
                text-align: center;
                padding: 10px;
                border-radius: 3px;
                margin-top:20px;
                text-transform: uppercase;
    
                h4{
                    font-family: "Montserrat-Bold";
                    font-size: 12px;
                    user-select: none;
    
                }
            }
        }
    }
}

@media only screen and  (min-width: 760px) and (max-width: 1280px) {
.bar{
    height: 3rem;
    font-size: 12px;
    padding: 10px;

    .logo{
        font-size: 20px;
        width: 7rem;
        justify-content: flex-start;
    }
    .search{
        width: 45%;


        input[type=text]{
            width: 100%;
            height: 30px;
            font-size: 18px;
        }
        .clearSearch{
            position: absolute;
            top: 5px;
            right: -5px;
            cursor: pointer;
            svg{
                height: 20px;
                width: 20px;
                stroke: $dark_text;
                transition: stroke 500ms ease-in-out;
                &:hover{
                    stroke: $dark_text_highlight
                }
            }
        }
    }
    
    .navigation{

        padding-right: 0.5rem;

        a{
            width:100px;
            margin:0;
        }
        .userMenu{
            padding-left:10px;
        }
        .filter{
            padding:0;
            margin: 0;
        }

    }
}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .bar{
        height: 3rem;
        font-size: 12px;
        padding: 10px;
    
        .logo{
            font-size: 20px;
            width: 7rem;
            justify-content: flex-start;
    
    
        }
        .search{
            width: 45%;
    
    
            input[type=text]{
                width: 100%;
                height: 30px;
                font-size: 18px;
                padding-bottom: 0;
            }
            .clearSearch{
                position: absolute;
                top: 5px;
                right: -5px;
                cursor: pointer;
                svg{
                    height: 20px;
                    width: 20px;
                    stroke: $dark_text;
                    transition: stroke 500ms ease-in-out;
                    &:hover{
                        stroke: $dark_text_highlight
                    }
                }
            }
        }
        
        .navigation{
    
            padding-right: 0.5rem;
    
            a{
                width:100px;
                margin:0;
            }
            .userMenu{
                padding-left:10px;
            }
            .filter{
                display: none;
                padding:0;
                margin: 0;
            }
    
        }
    }
}
