@import './styles/variables';


button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}
button{
  cursor: pointer;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

html{
  background-color: $dark_body;
}

.content {
  margin: 5.5rem 1em 1em 1em;
  min-height: calc(100vh - 10em);
  box-sizing: border-box;

  .loaderUp{
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FEFEFE;

    h2{
      color: #00ffed;
    }
  }
}

.content-full{
  margin: 1em;
    min-height: calc(100vh - 5rem);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}



button{
  height: 30px;
  font-size: 12px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  border-radius: 3px;
}
.swatch-view{
  display:flex;
  flex-wrap: wrap;
  gap: 4px;
}
.LazyLoad {
  opacity: 0;
  transition: all 400ms ease-in-out;
  overflow: hidden;
  filter:blur(6px);
  height:260px;
  width:100%;
}

.is-visible {
  opacity: 1;
  filter:blur(0px);
}
.submit-changes{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.basic-button{
  height: auto;
  background: #DDD;
  padding: 10px 20px;
  color: #222;
  border-radius: 2px;
  border: thin solid #222;
  width: 200px;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 2px;
}
.empty-collection{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(70vh - 3em);

  .empty-collection-message{
    width: 500px;
    display: flex;
    flex-direction: column;
    height: 400px;
    gap: 10px;
    background: #272b2bf8;
    padding: 20px;
    color:#FEFEFE;
    justify-content: space-between;

    .text-box{
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 20px;

      svg{
        transform: translate(0px, 4px);
        filter: drop-shadow(0px 0px 1px #000);
      }

      h4{
        opacity:0.8;
      }
    }

    
  }
}
.stored-header{
  display: flex;
  justify-content: space-between;
}
.stored-collection-box{
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  padding: 0px;
  justify-content: center;
  align-items: center;
  color: white;
}
.stored-collection-area{
  height: 100%;
    width: -moz-fit-content;
    width: 100%;
    background: #242828;
    padding: 20px;
    border: thin solid black;
    border-radius: 2px;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  h5{
    margin-bottom: 2px;
  }

  .stored-collection-button-group{
    display: flex;
    grid-gap: 2px;
    gap: 5px;
    overflow: scroll;
    height: 110px;
   

    .button{
      width: 100px;
      min-width: 100px;
      height: 100px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 50px;
      cursor: pointer;
      transition: all ease-in-out 200ms;
      overflow: hidden;
      border: thin solid #FEFEFE;

      
      h5,h6{
        background-color: #FEFEFE80;
        color: #000;
        width: 100%;
        text-align: center;
        font-size: unset;
        border-radius: 1px;
        padding: 2px 10px;
      }
    }
    .button:hover{
        filter: drop-shadow(0px 0px 0px #00000060);

    }
  }

 
}
.pdf-download{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    flex-basis: 30%;
    flex-grow: 5;

    .pdf-download-section{
      width: 60%;
      max-width: 750px;
      height: 170px;
      color: #00ffed;
      background-color: #272b2bf8;
      border-radius: 3px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-transform: uppercase;
      flex-direction: column;
      text-align: center;
      overflow: hidden;
      position: relative;
      transition: width 400ms ease-in-out;

        &.closed{
          width: 270px;
          justify-content: flex-start;
          align-items: center;
        }

      .pdf-download-header{
        background-color: #474747;
        color: #00ffed;
        margin-top: -5px;
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .clear-collection{
          font-size: 13px;
          opacity: 0.5;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all 200ms ease-in-out;

          svg{
            margin-left:3px;
            height:20px;
            width: 20px;
          }
          &:hover{
           
            opacity:1;
            color:#FEFEFE;
            stroke: #fefefe
          }
        }
      }
      .pdf-download-body{
        width:100%;
      }
      .pdf-download-footer{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px;
        margin: 2px;

        input[type="radio"], input[type="checkbox"]{
          margin: 5px 10px;
      }
      
      .pdf-download-button{
        border: thin solid #00ffed;
        color: #00ffed;
        background-color: transparent;
        font-size: 16px;
        padding: 10px 20px;
        transition: all 200ms ease-in-out;
        border-radius: 3px;
        min-width: 100px;
        cursor: pointer;


        &:hover{
          background-color: #00ffed;
          border-color: #222;
          color:#222;

        }
      }
      }
  
      input[type="text"], input[type="email"]{
        background: none;
        outline: none;
        border: none;
        border-bottom: dashed thin #00ffed;
        text-align: center;
        font-size: 18px;
        padding-bottom: 0px;
        color: #00ffed;
        width: 70%;
        margin-bottom: 0px;
        height: 30px;
    }
  }
}
.clear-search{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 275px;
  flex-basis: 350px;
  flex-grow: 5;
  
  .clear-search-button{
    width: 200px;
    height: 160px;
    color: #00ffed;
    background-color: #272b2bf8;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    filter: drop-shadow(2px 4px 6px black);
    transition: filter 200ms ease-in-out;
    padding: 40px 0px;

    .clear-text{
      color: #272b2bf8;
      margin-top: 10px;
      background-color: #fefefe;
      border: none;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 3px;
      transition: all 500ms ease-in-out;

      &:hover{
        background-color: #272b2bf8;
        color: #fefefe;
        border: thin solid #FEFEFE;
      }
    }

    &:hover{
      filter: drop-shadow(0px 0px 3px black);
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner{
  animation: spin 0.6s linear infinite;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border: 3px solid white;
  border-bottom: 3px solid gray;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  filter: drop-shadow(0px 0px 2px black) blur(1px);
  z-index: 1;
}

.active{
  
  color:$dark_text_highlight !important;

 span{
    background-color: $dark_text_highlight !important;
  }

}

.dropzone{
  height: 50px;
  width: auto;
  border: thick red dashed;
  padding: 10px 20px;
  box-sizing: content-box;
  margin: 20px;
  color: white;
  display: flex;
  align-items: center;

  div{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $dark_text_highlight;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.checkbox{
  width: 160px;
  font-size: 14px;
}
input[type='checkbox']:after {
  width: 15px;
  height: 15px;
  border-radius: 1px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='checkbox']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 1px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $dark_text_highlight;
  content: url('./images/link.svg'); // should be checkmark
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.footer-bar{
    background-color:#1c2220;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 3em;
      padding: 0 3em;
      color: #FEFEFE;
      height:30px;

      .footer-copy{
        font-size: 16px;
      }
      .footer-contact{
        font-size:16px;
      }
}

@media only screen and (max-width: 1280px) {
  .content{
    margin: 3.5rem 1em 1em 1em;
    min-height: calc(100vh - 7.5rem);
    box-sizing: border-box;
  }
}

/* Pricing Page Start */
.pricingPage{
  
  .count{
    background-color: #00ffed;
    color: #555555;
    width: 60px;
    height: 60px;
    border-radius: 40px;
    font-size: 20px;
    position: absolute;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .search-bar{
    display: flex;
    justify-content: center;
    position: relative;

    .search-icon{
      position: absolute;
      width: 30px;
      height: 30px;
      color: #00ffed;
      right: calc(24% + 8px);
      top: 20px;

      .closeIcon{
        cursor: pointer;

        :hover{
          color:#FEFEFE;
        }
      }
      .searchIcon{
        color: #FEFEFE;
      }

    }
      input[type="text"], input[type="email"]{
        width: 50%;
        height: 100%;
        background-color: transparent;
        border:none;
        font-family:"Montserrat";
        font-size: 20px;
        color: #DDD;
        outline:none;
        resize:none;
        padding: 10px;
        margin:5px;
        border-bottom: #FEFEFE thin solid;
        padding-bottom: 10px; 
        margin-bottom: 20px;
        text-align: center;
        outline: none;
        transition: all ease-in-out 200ms;

       
      }

  }
  .table-area{
    background-color: #474747;
    padding: 10px;

    table, th, td {
      border-collapse: collapse;
      border: 1px solid #555555;
      padding: 10px;
      color: #FEFEFE;
    }
    .table-head th{
      text-transform: uppercase;
      background-color: #222;
      color: #00ffed;
    }

    .table{
      width:100%;
    }
  }
   input[type="text"]{
    background-color: transparent;
    border: none;
    font-family: "Montserrat";
    font-size: 17px;
    margin: 5px;
    height: 24px;
    border-bottom: #FEFEFE thin solid;
    padding-bottom: 10px;
    color: #FEFEFE;
    text-align: center;
    outline: none;
    transition: all ease-in-out 200ms;
    
     
   }
   input[type="text"]:focus {
    border-bottom: #00ffed thin solid;
    color: #00ffed;
}

}
/* Pricing Page End */

@media only screen and (max-width: 765px){
.content {
    margin: 3.5rem 0.2em 1em 0.2em;
    min-height: calc(100vh - 7.5rem);
    box-sizing: border-box;
}
.content-full{
  margin: 1em 0em;
  min-height: calc(100vh - 4rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
}
.logo{
  font-size: 20px;
    width: 2rem;
    justify-content: flex-start;
}
.footer-bar{
  background-color:#1c2220;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2em;
    padding: 0 1em;
    color: #FEFEFE;
    height:30px;

    .footer-copy{
      font-size: 10px;
    }
    .footer-contact{
      font-size:10px;
    }
}
}